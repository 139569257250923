import {getCookie} from "@web2/request_utils";

const ALGOLYTICS_DOMAIN = "//app.scoring.one/";

declare let sce: any;
let isAlgolyticsInitialized = false;

/**
 * Init
 */
export const initAlgolytics = (algolyticsId?: string): void => {
    // if there is no ID provided, do not initialize
    if (!algolyticsId) {
        return;
    }

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isAlgolyticsInitialized) {
        return;
    }
    // initialize
    (function (base_url: string, write_key: string) {
        (window as any).sce = (window as any).sce = (window as any).sce || [];

        // already invoked
        if (sce.invoked) {
            if (window.console && console.error) {
                console.error("Probably, code already included");
            }

            return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        sce.invoked = true;
        sce.loadScriptFlag = false;
        // ("https:" === document.location.protocol ? "https://" : "http://")
        sce.baseUrl = base_url;
        sce.writeKey = write_key;
        sce.scenario = "www_events_gh";
        sce.CODES_V = "0.3";
        sce.buffer = sce.buffer || [];

        // fetch main script
        const firstScriptElem = document.getElementsByTagName("script")[0] as any;
        const newScriptElem = document.createElement("script");
        newScriptElem.async = true;
        newScriptElem.src = `${sce.baseUrl}exposed/sendData.js`;
        firstScriptElem.parentNode.insertBefore(newScriptElem, firstScriptElem);

        // public API
        sce.event = function (eventType: string, arg: any) {
            if (sce.loadScriptFlag) {
                sce.sendData(eventType, arg, null, "www_events_gh");
            } else {
                sce.buffer.push([Date.now(), eventType, arg, "www_events_gh"]);
            }
        };
    })(ALGOLYTICS_DOMAIN, algolyticsId);
    isAlgolyticsInitialized = true;
};

export const pageViewAlgolytics = delayHit((payload: any) => hitAlgolytics("views", payload), 500);
export const searchViewAlgolytics = delayHit((payload: any) => hitAlgolytics("search", payload), 500);
export const showPhoneNumberAlgolytics = delayHit((payload: any) => hitAlgolytics("phone_number", payload), 500);
export const favouriteAlgolytics = delayHit((payload: any) => hitAlgolytics("favourite_click", payload), 500);
export const applicationAlgolytics = delayHit((payload: any) => hitAlgolytics("application", payload), 500);
export const paywallAlgolytics = delayHit((payload: any) => hitAlgolytics("paywall", payload), 500);

/**
 * HIT
 */
let savedCookie: string | undefined = "";
const hitAlgolytics = (collection: string, data: any = {}) => {
    if (!isAlgolyticsInitialized) {
        return;
    }

    if (!savedCookie) {
        savedCookie = getCookie("analyticssessionid");
    }

    sce &&
        sce.event(collection, {
            ...data,
            ...(savedCookie ? {session_id: savedCookie} : {})
        });
};

function delayHit(cb: any, time: number) {
    let hitTimeout = 0;

    return (...args: any[]) => {
        clearTimeout(hitTimeout);
        hitTimeout = window.setTimeout(() => cb(...args), time);
    };
}
